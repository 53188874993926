import GeneralContext from "../store/general";
import { useContext } from "react";
import Link from "next/link";

export default function Breadcrumbs() {
  const generalContext = useContext(GeneralContext);

  let breadcrumbs =
    generalContext.breadcrumbs && generalContext.breadcrumbs.itemListElement;

  return breadcrumbs && breadcrumbs.length ? (
    <div className="breadcrumbs">
      {breadcrumbs.map((breadcrumb, i) => {
        return (
          <span key={breadcrumb.name + i}>
            {breadcrumb.item ? (
              <Link href={breadcrumb.item}>{breadcrumb.name}</Link>
            ) : (
              <span>{breadcrumb.name}</span>
            )}
            {i + 1 < breadcrumbs.length && (
              <i className=" fas fa-arrow-alt-right" />
            )}
          </span>
        );
      })}
    </div>
  ) : null;
}
