import { createElement, Fragment } from "react";
import CbBanner_1 from "./cb-banner_1";
import CbCta_1 from "./cb-cta_1";
import CbContact from "./cb-contact";
import CbImpact from "./cb-impact";
import CbSteps_1 from "./cb-steps_1";
import CbSteps_2 from "./cb-steps_2";
import CbBanner_2 from "./cb-banner_2";
import CbUsps from "./cb-usps";
import CbImageAndText from "./cb-image_and_text";
import CbQuote from "./cb-quote";
import CbCta_2 from "./cb-cta_2";
import CbFaq from "./cb-faq";
import CbReviews from "./cb-reviews";
import cbSavingsExample from "./cb-savings_example";
import cbReviewsSlider from "./cb-reviews_slider";
import CbBanner3 from "./cb-banner_3";
import CbVideo from "./cb-video";
import CbTimeline from "./cb-timeline";
import CbTeam from "./cb-team";
import CbBanner4 from "./cb-banner_4";
import CbForm from "./cb-form";
import CbOffer from "./cb-offer";
import CbInPageNavigation from "./cb-in_page_navigation";
import CbImage from "./cb-image";
import CbContactInformation from "./cb-contact_information";
import CbEmbedCode from "./cb-embed_code";
import CbRealtimeEnergyPrices from "./cb-realtime_energy_prices";

export default function Cb({ blocks }) {
  const map = {
    "cb-banner_1": CbBanner_1,
    "cb-banner_2": CbBanner_2,
    "cb-banner_3": CbBanner3,
    "cb-contact": CbContact,
    "cb-cta_1": CbCta_1,
    "cb-impact": CbImpact,
    "cb-steps_1": CbSteps_1,
    "cb-steps_2": CbSteps_2,
    "cb-usps": CbUsps,
    "cb-image_and_text": CbImageAndText,
    "cb-quote": CbQuote,
    "cb-cta_2": CbCta_2,
    "cb-faq": CbFaq,
    "cb-reviews": CbReviews,
    "cb-savings_example": cbSavingsExample,
    "cb-reviews_slider": cbReviewsSlider,
    "cb-video": CbVideo,
    "cb-timeline": CbTimeline,
    "cb-team": CbTeam,
    "cb-banner_4": CbBanner4,
    "cb-form": CbForm,
    "cb-offer": CbOffer,
    "cb-in_page_navigation": CbInPageNavigation,
    "cb-image": CbImage,
    "cb-contact_information": CbContactInformation,
    "cb-embed_code": CbEmbedCode,
    "cb-realtime_energy_prices": CbRealtimeEnergyPrices,
  };

  if (blocks && blocks.length > 0) {
    let els = [];
    for (let i = 0; i < blocks.length; i++) {
      const block = blocks[i];

      els.push(
        <section
          className={`cb cb--${block.acf_fc_layout}`}
          key={block.key}
          id={block.id}
          data-bg-color={
            block.settings && block.settings.background_color
              ? block.settings.background_color
              : null
          }
          data-font-color={
            block.settings && block.settings.font_color
              ? block.settings.font_color
              : null
          }
          data-edge-top={
            block.settings &&
            block.settings.has_edge_top &&
            block.settings.edge_top
              ? block.settings.edge_top
              : null
          }
          data-edge-bottom={
            block.settings &&
            block.settings.has_edge_bottom &&
            block.settings.edge_bottom
              ? block.settings.edge_bottom
              : null
          }
          data-prev-cb-edge-bottom={block.prev_cb_edge_bottom ?? null}
          data-next-cb-edge-top={block.next_cb_edge_top ?? null}
          data-prev-cb={block.prev_cb ?? null}
          data-next-cb={block.next_cb ?? null}
        >
          {createElement(map["cb-" + block.acf_fc_layout], {
            block: block,
          })}
        </section>
      );
    }
    return createElement(Fragment, null, els);
  }
}
