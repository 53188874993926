export default function CbContactInformation({ block }) {
  return (
    <>
      <div className="container">
        <div className="frame">
          <div className="bit-xxs-12 pt-0 pb-0 text-center">
            {block.title && <h3 className="h4 pb-20">{block.title}</h3>}
            {block.company_name && (
              <p className="text--gray text--weight-bold pb-0">
                {block.company_name}
              </p>
            )}
            {block.address && (
              <div
                className="pb-20"
                dangerouslySetInnerHTML={{ __html: block.address }}
              />
            )}
            {block.phone && (
              <p className="lead pb-0">
                <span>Telefoon: </span>
                <span className="text--weight-bold text--gray">
                  {block.phone}
                </span>
              </p>
            )}
            {block.text_after_phone && (
              <p className="text--italic pb-20">{block.text_after_phone}</p>
            )}
            {block.email && (
              <p className="lead pb-0">
                <span>E-mail: </span>
                <a
                  href={`mailto:${block.email}`}
                  className="text--weight-bold text--gray"
                >
                  {block.email}
                </a>
              </p>
            )}
            {block.text_after_email && (
              <p className="text--italic pb-0">{block.text_after_email}</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
