import Button from "../button";
import TwoImagesAndLogo from "../two-images-and-logo";

export default function CbUsps({ block, cssClasses }) {
  return (
    <>
      <div className="container">
        <div className="cb--usps__box">
          <div className="frame frame--justify-lg-space-between m-0">
            <div className="bit-xxs-12 bit-md-6 p-0 pb-50">
              {block.title && (
                <div
                  className="cb--usps__title pb-10"
                  dangerouslySetInnerHTML={{ __html: block.title }}
                />
              )}
              {block.text && (
                <div
                  className="pb-10 pb-md-20"
                  dangerouslySetInnerHTML={{ __html: block.text }}
                />
              )}
              <div className="pb-20 pb-md-30 pl-md-40">
                {block.usps.length &&
                  block.usps.map((usp) => {
                    return (
                      <div className="flex" key={usp.text}>
                        <i className="fas fa-plus-circle mt-5 mr-10" />
                        {usp.text && <p className="pb-10">{usp.text}</p>}
                      </div>
                    );
                  })}
              </div>
              {block.button && (
                <Button button={block.button} classes="button" />
              )}
            </div>
            <div className="bit-xxs-12 bit-md-6 bit-lg-5 p-0">
              {block.images && block.images.length && (
                <TwoImagesAndLogo
                  images={block.images}
                  isGreen={block.settings.font_color === "green"}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
