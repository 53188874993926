import Accordion from "./accordion";

export default function Faq({block}) {
    return (<div className="container">
        <div className="frame frame--justify-sm-center m-0">
            <div className="bit-xxs-12 p-0">
                {block.title && (<div
                    className="cb--faq__title pb-20"
                    dangerouslySetInnerHTML={{__html: block.title}}
                />)}
            </div>
            <div className="bit-xxs-12 bit-sm-8 bit-md-10 p-0">
                <div className="frame">
                    {block.questions.length && block.questions.map((question) => {
                        return (<div
                            className="bit-xxs-12 bit-md-6 pb-0 pb-md-5"
                            key={question.title}
                        >
                            <Accordion
                                title={question.title}
                                content={question.content}
                                color={block.settings && block.settings.font_color ? block.settings.font_color : null}
                            />
                        </div>);
                    })}
                </div>
            </div>
        </div>
    </div>)
}