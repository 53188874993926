import Button from "../button";

export default function CbBanner_1({ block }) {
  return (
    <>
      <div className="container pt-30 pt-sm-50 pt-md-75">
        <div className="frame frame--justify-sm-center frame--justify-md-flex-start">
          <div className="bit-xxs-12 bit-sm-9 bit-md-9 bit-lg-7">
            {block.title && (
              <div
                className="cb--banner_1__title"
                dangerouslySetInnerHTML={{ __html: block.title }}
              />
            )}
            {block.text && (
              <div
                className="pb-10 pr-md-100 text--weight-normal"
                dangerouslySetInnerHTML={{ __html: block.text }}
              />
            )}
            <div className="pb-20 pb-sm-40 pl-20">
              {block.usps.length &&
                block.usps.map((usp) => {
                  return (
                    <p
                      key={usp.text}
                      className="flex flex--align-flex-start text--italic text--weight-demi text--lineheight-tight pb-10 pb-md-15"
                    >
                      <i className="far fa-check-circle mt-2 mt-md-0 mr-5 mr-md-10" />
                      <span>{usp.text}</span>
                    </p>
                  );
                })}
            </div>
            <div className="flex">
              {block.button_left && (
                <Button
                  button={block.button_left}
                  classes="button button--green-light button--white-shadow mr-20 mr-md-30"
                />
              )}
              {block.button_right && (
                <Button
                  button={block.button_right}
                  classes="button button--gray button--white-shadow"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <figure className="cb--banner_1__image">
        {block.image_src && <img src={block.image_src} alt="" />}
      </figure>
    </>
  );
}
