import {useEffect, useState} from "react";
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip,} from 'chart.js';
import {Bar} from 'react-chartjs-2';
import moment from "moment";
import 'moment/locale/nl';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
);

const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: 'top',
        },
        tooltip: {
            callbacks: {
                label: function (context) {
                    let label = context.dataset.shortLabel || '';

                    if (label) {
                        label += ': ';
                    }

                    if (context.parsed.y !== null) {
                        label += new Intl.NumberFormat('nl-NL', {
                            style: 'currency',
                            currency: 'EUR'
                        }).format(context.parsed.y);
                    }
                    return label;
                }
            }
        }
    },
    scales: {
        y: {
            ticks: {
                callback: function (value, index, ticks) {
                    return new Intl.NumberFormat('nl-NL', {style: 'currency', currency: 'EUR'}).format(value);
                }
            },
        },
        x: {
            grid: {
                display: false,
            },
        }
    }
};

const getISOString = date => {
    date.set('hour', 0);
    date.set('minute', 0);
    date.set('second', 0);
    date.set('millisecond', 0);

    return date.toISOString();
}

const getApiUrl = (startDate, endDate, timePeriod = 'day', type = 'electricity') => {
    let start = getISOString(startDate)
    let end = getISOString(endDate)

    let grouping = 'grouping='
    let energyType = 'getapxtariffs'

    if (timePeriod === 'week') {
        grouping = 'grouping%5Btype%5D=ByDay&grouping%5BtimeZoneId%5D=W.%20Europe%20Standard%20Time'
    } else if (timePeriod === 'month') {
        grouping = 'grouping%5Btype%5D=ByDay&grouping%5BtimeZoneId%5D=W.%20Europe%20Standard%20Time'
    } else if (timePeriod === 'year') {
        grouping = 'grouping%5Btype%5D=ByMonth&grouping%5BtimeZoneId%5D=W.%20Europe%20Standard%20Time'
    }

    if (type === 'gas') {
        energyType = 'getlebatariffs'
    }

    return `https://mijn.easyenergy.com/nl/api/tariff/${energyType}?startTimestamp=${start}&endTimestamp=${end}&${grouping}&includeVat=false`;
}

function getBarLabel(timestamp, showAs = 'hour') {
    let t = Date.parse(timestamp);
    t = new Date(t);

    if (showAs === 'hour') {
        return t.toLocaleTimeString('nl-NL', {
            hour: "2-digit",
            minute: "2-digit"
        })
    } else if (showAs === 'date') {
        return t.toLocaleDateString('nl-NL', {
            day: 'numeric',
            month: 'short',
        })
    } else if (showAs === 'month') {
        return t.toLocaleDateString('nl-NL', {
            month: 'short',
        })
    }

    return timestamp;
}

const data = {
    energyTypes: [
        {
            name: 'electricity',
            title: 'Stroom',
            chartLabel: 'Prijs voor stroom per uur (kWh)',
            averageLabel: 'Gemiddelde inkoopprijs voor stroom',
        },
        {
            name: 'gas',
            title: 'Gas',
            chartLabel: 'Prijs voor gas per uur (m3)',
            averageLabel: 'Gemiddelde inkoopprijs voor gas',
        },
    ],
    timePeriods: [
        {
            name: 'day',
            title: 'Dag',
            barLabel: 'hour',
        },
        {
            name: 'week',
            title: 'Week',
            barLabel: 'date',
        },
        {
            name: 'month',
            title: 'Maand',
            barLabel: 'date',
        },
        {
            name: 'year',
            title: 'Jaar',
            barLabel: 'month',
        }
    ]
}

export default function EnergyPricesChart() {
    const today = moment().locale('nl')

    const [startDate, setStartDate] = useState(today.clone());
    const [endDate, setEndDate] = useState(today.clone().add(1, 'day'));
    const [pricesData, setPricesData] = useState(null);

    const [energyType, setEnergyType] = useState('electricity'); // electricity, gas
    const [timePeriod, setTimePeriod] = useState('day'); // day, week, month, year

    const [timePeriodLabel, setTimePeriodLabel] = useState('')
    const [chartLabel, setChartLabel] = useState('');
    const [shortChartLabel, setShortChartLabel] = useState('');
    const [barLabels, setBarLabels] = useState([]); // hour, date, month

    useEffect(() => {
        if (timePeriod === 'day') {
            setStartDate(today.clone())
            setEndDate(today.clone().add(1, 'day'))
        } else if (timePeriod === 'week') {
            setStartDate(today.clone().startOf('isoWeek'))
            setEndDate(today.clone().endOf('isoWeek'))
        } else if (timePeriod === 'month') {
            setStartDate(today.clone().startOf('month'))
            setEndDate(today.clone().endOf('month'))
        } else if (timePeriod === 'year') {
            setStartDate(today.clone().startOf('year'))
            setEndDate(today.clone().endOf('year'))
        }
    }, [timePeriod]);

    useEffect(() => {
        fetch(process.env.NEXT_PUBLIC_CORS_PROXY_URL + getApiUrl(startDate, endDate, timePeriod, energyType))
            .then((response) => response.text())
            .then((textResponse) => setPricesData(JSON.parse(textResponse)))
            .catch((error) => {
                console.log(error);
            });
    }, [startDate, endDate, energyType])

    useEffect(() => {
        if (pricesData) {
            setChartLabel(data.energyTypes.find(e => e.name === energyType).chartLabel)
            setShortChartLabel(data.energyTypes.find(e => e.name === energyType).title)
            setBarLabels(pricesData.map(p => getBarLabel(p.Timestamp, data.timePeriods.find(t => t.name === timePeriod).barLabel)));
        }
    }, [pricesData]);

    useEffect(() => {
        if (timePeriod === 'day') {
            setTimePeriodLabel(endDate.clone().subtract(1, 'day').format('LL'))
        } else if (timePeriod === 'week') {
            const start = startDate.clone().format('D MMMM')
            const end = endDate.clone().format('D MMMM')

            setTimePeriodLabel(start + ' - ' + end)
        } else if (timePeriod === 'month') {
            setTimePeriodLabel(startDate.clone().format('MMMM YYYY'))
        } else if (timePeriod === 'year') {
            setTimePeriodLabel(startDate.clone().format('YYYY'))
        }
     }, [endDate, timePeriod])

    if (!pricesData) {
        return <p className="text--align-center text--weight-bold">
            Sorry, er ging iets fout bij het laden van de energieprijzen.</p>
    }

    const chartData = {
        labels: barLabels,
        datasets: [
            {
                label: chartLabel,
                shortLabel: shortChartLabel,
                data: pricesData.map(p => p.TariffReturn),
                backgroundColor: '#04B8F0',
                borderRadius: 100,
                categoryPercentage: 0.5,
            },
        ],
    };

    const nextClickHandler = () => {
        setStartDate(startDate.clone().add(1, timePeriod))
        setEndDate(endDate.clone().add(1, timePeriod))
    }

    const prevClickHandler = () => {
        setStartDate(startDate.clone().subtract(1, timePeriod))
        setEndDate(endDate.clone().subtract(1, timePeriod))
    }

    return (
        <div>
            <div className="flex flex--justify-space-between">
                <div className="flex">
                    {data.energyTypes.map(e => (
                        <label key={e.name}>
                            <input type="radio" value={e.name} name="energyType"
                                   checked={energyType === e.name}
                                   onChange={() => setEnergyType(e.name)}/>
                            {e.title}
                        </label>
                    ))}
                </div>
                <div className="flex">
                    {data.timePeriods.map(t => {
                        return (
                            <label key={t.name}>
                                <input type="radio" value={t.name} name="timePeriod" checked={timePeriod === t.name}
                                       onChange={() => setTimePeriod(t.name)}/>
                                {t.title}
                            </label>
                        )
                    })}
                </div>
            </div>
            <div>
                <p>start date: {getISOString(startDate)}</p>
                <p>end date: {getISOString(endDate)}</p>
            </div>
            <div className="flex flex--justify-center pt-20 pb-20">
                <button className="button--link" onClick={prevClickHandler}>Previous</button>
                <p className="pl-10 pr-10">{timePeriodLabel}</p>
                <button className="button--link" onClick={nextClickHandler}>Next</button>
            </div>

            <div style={{minHeight: '50vh', width: '100%'}}>
                {
                    Array.isArray(pricesData) && pricesData.length === 0 ?
                        <p className="text--align-center text--weight-bold">
                            Momenteel is er nog geen gemeten data beschikbaar om weer te geven.
                        </p> :
                        <Bar options={chartOptions} data={chartData}/>
                }
            </div>

        </div>
    )
}